









import { Component, Vue, Prop } from 'vue-property-decorator';
import IuguIcon from '@/assets/images/iugu-icon.svg';

@Component
export default class ContextIugu extends Vue {
  @Prop() public readonly iugu!: string;

  public get iuguIcon(): string {
    return IuguIcon;
  }
}
