import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.t('colunas.id') as string,
    key: 'id',
    sortable: true,
    thStyle: { width: '50px' },
    stickyColumn: true,
  },
  {
    label: i18n.tc('colunas.codigo.usuario', 1) as string,
    key: 'id_usuario',
    sortable: true,
    thStyle: { width: '50px' },
  },
  {
    label: i18n.tc('colunas.nome.usuario', 1) as string,
    key: 'usuarios.nome',
    sortable: true,
  },
  {
    label: i18n.t('colunas.valor.fatura') as string,
    key: 'valor',
    sortable: true,
    thStyle: { width: '120px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: true,
    thStyle: { width: '90px' },
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    key: 'data_criacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    key: 'data_vencimento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    key: 'data_pagamento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
];

const situacoes: ISelectSituacao[] = [
  {
    text: i18n.t('faturas.situacoes.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('faturas.situacoes.pendente') as string,
    value: 'pending',
    variant: 'secondary',
  },
  {
    text: i18n.t('faturas.situacoes.pago') as string,
    value: 'paid',
    variant: 'success',
  },
  {
    text: i18n.t('faturas.situacoes.cancelado') as string,
    value: 'canceled',
    variant: 'warning',
  },
  {
    text: i18n.t('faturas.situacoes.vencido') as string,
    value: 'expired',
    variant: 'danger',
  },
];

const columnsFilter: IFiltroField[] = [
  {
    label: i18n.t('colunas.id') as string,
    id: 'faturas.id',
    type: 'id',
  },
  {
    label: i18n.t('colunas.indicacao') as string,
    id: 'faturas.id_indicacao',
    type: 'id',
  },
  {
    label: i18n.tc('colunas.nome.vendedor', 1) as string,
    id: 'usuarios.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.nome.negocio', 2) as string,
    id: 'simulacoes.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    id: 'faturas.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.valor.protecao') as string,
    id: 'simulacoes.valor_fipe',
    type: 'number',
  },
  {
    label: i18n.t('colunas.valor.fatura') as string,
    id: 'faturas.valor',
    type: 'number',
  },
  {
    label: i18n.t('colunas.tipo') as string,
    id: 'faturas.tipo',
    type: 'enum',
    enum: [
      { value: 'boleto1', label: i18n.t('faturas.metodo.entrada') as string },
      { value: 'boleto2', label: i18n.t('faturas.metodo.parcela') as string },
      { value: 'cartao', label: i18n.t('faturas.metodo.cartao') as string },
    ],
  },
  {
    label: i18n.t('colunas.situacao') as string,
    id: 'faturas.situacao',
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    id: 'faturas.data_criacao',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    id: 'faturas.data_vencimento',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    id: 'faturas.data_pagamento',
    type: 'date',
  },
];

export { columns, situacoes, columnsFilter };
